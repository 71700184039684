<template>
  <div />
</template>

<script>
export default {
  name: 'OneTrust',
  mounted() {
    const first_script = document.createElement('script');
    first_script.type = 'text/javascript';
    first_script.src = `https://cdn.cookielaw.org/consent/${process.env.VUE_APP_ONETRUST}/OtAutoBlock.js`;

    const second_script = document.createElement('script');
    second_script.type = 'text/javascript';
    second_script.setAttribute("data-domain-script", "90f93c7a-b566-4f83-907f-0ea342bb247b");
    second_script.setAttribute("charset", "UTF-8");
    second_script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    document.body.appendChild(first_script);
    document.body.appendChild(second_script);

    first_script.onload = function OptanonWrapper() { }
  },
}
</script>
