export default {
  dateFrom: '2012-01-01',
  dateTo: 'now',
  langs: [],
  products: ['multimedia', 'factcheck'],
  query: '',
  size: 10,
  sortField: 'published',
  sortOrder: 'desc',
  urgencies: [],
  sources: [],
  topics: []
}
