var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_vm._m(0),_c('a',{staticClass:"navbar-burger burger",class:{
        'is-active': _vm.showMobileMenu
      },attrs:{"role":"button"},on:{"click":function($event){_vm.showMobileMenu = !_vm.showMobileMenu}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"navbar-menu",class:{
      'is-active': _vm.showMobileMenu
    }},[_c('div',{staticClass:"navbar-end"},[_c('a',{key:"about",staticClass:"navbar-item",attrs:{"name":"about","aria-label":"about"},on:{"click":function($event){$event.preventDefault();return _vm.openAbout.apply(null, arguments)}}},[_c('icon-base',{attrs:{"icon-name":"about","width":24,"height":24}},[_c('icon-about')],1),_vm._v(" "+_vm._s(_vm.$t("about.name"))+" ")],1),_c('a',{staticClass:"navbar-item",attrs:{"aria-label":"Log out"},on:{"click":function($event){$event.preventDefault();return _vm.logoutHandler.apply(null, arguments)}}},[_c('icon-base',{attrs:{"icon-name":"logout"}},[_c('icon-logout')],1),_vm._v(" "+_vm._s(_vm.$t("auth.logout"))+" ")],1),_c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.locale))+" ")]),_c('div',{staticClass:"navbar-dropdown is-right"},[_c('a',{staticClass:"navbar-item",class:{
              'is-language': _vm.locale == 'fr',
            },on:{"click":function($event){return _vm.changeLocale('fr')}}},[_vm._v(" FR ")]),_c('a',{staticClass:"navbar-item",class:{
              'is-language': _vm.locale == 'en',
            },on:{"click":function($event){return _vm.changeLocale('en')}}},[_vm._v(" EN ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-item",attrs:{"href":"#"}},[_c('img',{attrs:{"alt":"AFP Stories","src":require("@/assets/img/afp_logo.png")}})])}]

export { render, staticRenderFns }