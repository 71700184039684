import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm';
import router from "../router";

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    queryParams: {
      gtm_cookies_win: 'x'
    },
    debug: false,
    vueRouter: router,
  });
