import AfpNews from "@/afpnews-api/afpnews-api";
import store from "@/store";

const afpNews = new AfpNews({
  baseUrl: "https://afp-apicore-prod.afp.com",
  customAuthUrl:
    "https://afp-apicore-prod.afp.com/tokens/",
  saveToken: token => {
    if (token) {
      store.commit("setToken", token);
    }
  }
});

export default afpNews;
