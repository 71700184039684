<template>
  <div id="app">
    <router-view />
    <OneTrust />
    <About v-show="isShowAboutSection" />
  </div>
</template>

<script>
import CheckConnection from '@/mixins/checkConnection'
import OneTrust from '@/components/OneTrust'
import About from '@/views/About'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: { OneTrust, About },
  mixins: [
    CheckConnection
  ],
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'isShowAboutSection',
    ])
  }
}


</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>

<style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  #app {
    height: 100%;
  }
</style>
