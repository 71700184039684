/* eslint-disable @typescript-eslint/naming-convention */
import btoa from "btoa-lite";
import {
  AuthorizationHeaders,
  AuthType,
  ClientCredentials,
  Token,
} from "./types";
import { get, postForm } from "./utils/request";
import axios from "axios"

export default class AfpNewsAuth {
  public token: Token | undefined;

  protected baseUrl: string;

  private apiKey: string | undefined;
  private customAuthUrl: string | undefined;
  private saveToken: Function;

  constructor({
    apiKey,
    clientId,
    clientSecret,
    baseUrl,
    customAuthUrl,
    saveToken,
  }: ClientCredentials & {
    baseUrl?: string;
    saveToken?: (token: Token | null) => void;
  } = {}) {
    this.credentials = { apiKey, clientId, clientSecret, customAuthUrl };
    this.baseUrl = baseUrl || " https://afp-apicore-prod.afp.com";
    if (saveToken) {
      this.saveToken = saveToken;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.saveToken = (token: Token) => {};
    }
  }

  set credentials({
    clientId,
    clientSecret,
    apiKey,
    customAuthUrl,
  }: ClientCredentials) {
    if (clientId && clientSecret) {
      delete this.customAuthUrl;
      this.apiKey = btoa(`${clientId}:${clientSecret}`);
    } else if (apiKey) {
      delete this.customAuthUrl;
      this.apiKey = apiKey;
    } else if (customAuthUrl) {
      delete this.apiKey;
      this.customAuthUrl = customAuthUrl;
    }
  }

  get authUrl(): string {
    if (this.customAuthUrl) {
      return this.customAuthUrl;
    }
    return `${this.baseUrl}/tokens`;
  }

  get isTokenValid(): boolean {
    return (this.token as Token).tokenExpires > +new Date();
  }

  get authorizationBearerHeaders(): AuthorizationHeaders {
    if (!this.token) {
      return {};
    }
    return {
      Authorization: `Bearer ${this.token.accessToken}`,
    };
  }

  public async authenticate({
    username,
    password,
  }: { username?: string; password?: string } = {}): Promise<Token> {
    if (this.apiKey) {
      if (username && password) {
        return this.getToken({ username, password });
      }

      if (this.token === undefined) {
        throw new Error("You need to authenticate with credentials once");
      }

      if (this.isTokenValid === false) {
        return this.requestRefreshToken();
      }

      return this.token;
    }

    if (this.customAuthUrl) {
      if (username && password) {
        return this.getToken({ username, password });
      }

      if (
        this.token &&
        this.isTokenValid === false &&
        this.token.authType === "credentials"
      ) {
        return this.requestRefreshToken();
      }
    }

    if (username && password) {
      throw new Error("You need an api key to make authenticated requests");
    }

    if (this.token && this.isTokenValid === true) {
      return this.token;
    }

    return this.requestAnonymousToken();
  }

  public resetToken(): void {
    delete this.token;
    this.saveToken(null);
  }

  private async requestAnonymousToken(): Promise<Token> {
    const token = await get(this.authUrl, {
      params: {
        grant_type: "anonymous",
      },
    });

    return this.parseToken(token, "anonymous");
  }

  get authorizationBasicHeaders(): AuthorizationHeaders {
    if (this.customAuthUrl || !this.apiKey) {
      return {};
    }
    return {
      Authorization: `Basic ${this.apiKey}`,
    };
  }

  private async requestAuthenticatedToken({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<Token> {
    const token = await postForm(
      this.authUrl,
      {
        password,
        username,
        clientID: "ondeck",
      },
      {
        headers: this.authorizationBasicHeaders,
      }
    );
    return this.parseToken(token, "credentials");
  }

  public async getToken({username, password}: {username: string, password: string}) : Promise<Token>{
      const api = this.apiKey || this.customAuthUrl || ''
      let token = {access_token: "", expires_in: 0, refresh_token: ""}
      await axios.post(api, {username, password, clientID: 'ondeck'}, {headers: { 'Content-Type': 'application/json'}}).then((response) =>{
        token = {access_token: response.data[0].access_token, expires_in: response.data[0].expires_in, refresh_token: response.data[0].refresh_token}
      })
      return this.parseToken(token, "credentials");
  }

  private async requestRefreshToken(): Promise<Token> {
    const { refreshToken, authType } = this.token as Token;
    const newToken = await postForm(
      this.authUrl,
      {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
      {
        headers: this.authorizationBasicHeaders,
      }
    );

    return this.parseToken(newToken, authType);
  }

  private parseToken(
    {
      access_token,
      refresh_token,
      expires_in,
    }: {
      access_token: string;
      refresh_token: string;
      expires_in: number;
    },
    authType: AuthType
  ): Token {
    this.token = {
      accessToken: access_token,
      authType,
      refreshToken: refresh_token,
      tokenExpires: +new Date() + expires_in * 1000,
    };
    this.saveToken(this.token);

    return this.token;
  }

  // public async me () {
  //   await this.authenticate()

  //   const { user } = await get(`${this.baseUrl}/v1/user/me`, {
  //     headers: this.authorizationBearerHeaders
  //   })

  //   return {
  //     username: user.username,
  //     email: user.email
  //   }
  // }
}
