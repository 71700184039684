<template>
  <panel
    class="about"
    @close="hideAboutSection()"
  >
    <template #actions>
      <button
        @click="hideAboutSection()"
        aria-label="Close"
        class="btn btn-icon close"
      >
        <i class="UI-icon UI-close-alt icon-small" />
    </button>
    </template>
    <template #header>
      <h3>{{ $t('about.title') }}</h3>
    </template>
    <template #body>
      <div>
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">Paramètres des cookies</button>
        <div id="ot-sdk-cookie-policy"></div>
      </div>
    </template>
  </panel>
</template>

<script>
import Panel from '@/components/Panel'
import { mapActions } from 'vuex'




export default {
  name: 'About',
  metaInfo: {
    title: 'About'
  },
  components: { Panel },
  methods:{
    ...mapActions(['hideAboutSection'])
  }
}
</script>

<style lang="scss" scoped>
.about{
  a.close {
    display: block;
    text-align: right;
    i{
      font-size: 20px;
      font-weight: 600;
    }
  }

  h3{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p{
    margin-bottom: 1rem;
  }
}

</style>
